<template>
  <div id="page-localization">
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section first-section mycontainer-main p-10" >
        <p>{{$route.params.title}} </p>
        <div>
          <ul class="list-group" v-if="resultList.length >0">
            <li class="list-group-item" v-for="item in resultList" @click="checkValidOutlet(item)">
              <a href="javascript: void(0)" style="text-decoration: none">
                {{item.kanji_address}}
              </a>
            </li>
          </ul>
          <p v-else>{{message}}</p>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import ASAPPopup from '../components/popup/OrderASAP.vue'
import * as action from '../store/actions'

import {
  mapActions
} from 'vuex'
export default {
  components: {},
  beforeDestroy () {
    this.$root.$off('onCheckValidOutletZipCode')
  },
  mounted () {
    let pageInfo = {
      screen: {
        name: 'localisation.address-select',
        type: 'localisation'
      }
    }
    this.eventTrackingScreenLoad(this, pageInfo)
    var vm = this
    this.$root.$on('onCheckValidOutletZipCode', (data) => {
      vm.checkValidOutlet(data)
    })
    this.$disposition.init(this)
    this.searchZipCode = this.$route.params.zip
    this.initData()
  },
  data () {
    return {
      searchZipCode: '',
      resultList: '',
      message: '',
      disposition: {}
    }
  },
  methods: {
    ...mapActions({
      setDisposition: action.SET_DISPOSITION
    }),
    initData () {
      this.resultList = []
      this.message = ''
      if (!this.searchZipCode) {
        return false
      }
      var vm = this
      this.$bus.$emit('loading', 'block')
      this.axios.apiOutlet.getOutletByAddressCode({zip_code: this.searchZipCode}, function (response) {
        let resultList = response.data.data.items
        vm.resultList = resultList
        if (vm.resultList.length <= 0) {
          vm.message = vm.$t('localization.not_store')
        }
        vm.$bus.$emit('loading', 'none')
      }, function (error) {
        console.log(`error: ${error}`)
        vm.message = vm.$t('localization.not_store')
        vm.$bus.$emit('loading', 'none')
      })
    },
    /**
     * Checking outlet is available
     * */
    checkValidOutlet (outlet) {
      var vm = this
      var outletId = outlet.id
      var outletData = outlet
      let data = {order_type: 'D', order_time: this.convertDateTimeFromTimezoneToUTC(this.$disposition.getByKey(this, 'timezone', ''), this.$disposition.getByKey(this, 'collectionTimeValue', '')), advance_order: this.$disposition.getByKey(this, 'advanceOrder', 0) || 0, outlet_id: outletId}
      this.axios.apiOutlet.checkOutletAvailable(data,
        function (response) {
          response = response.data
          if (response.status) {
            let data = vm.$disposition.get(vm)
            response = response.data.item
            // set data
            data.lat = response.lat
            data.long = response.long
            data.postalCode = response.zip
            data.locationName = outletData.kanji_address
            data.outletAddress = outletData.kanji_address
            data.orderType = vm.$const.DELIVERY
            data.outletId = response.id
            data.outletName = response.name
            data.outletCode = response.code
            data.addressCode = outletData.address_code
            data.advanceOrder = vm.$disposition.getByKey(vm, 'advanceOrder', 0) || 0
            data.tier = response.tier
            data.minCart = response.min_cart
            data.houseNo = ''
            data.landmark = ''
            data.quoteTime = response.quote_time
            vm.disposition = data
            let cart = vm.$cart.get(vm)
            if (cart.uuid) {
              vm.$modal.show('dialog', {
                text: vm.$t('confirm.remove_cart'),
                width: 300,
                buttons: [
                  {
                    title: vm.$t('button_yes'),
                    handler: () => {
                      vm.proceessDispostion()
                      vm.$modal.hide('dialog')
                    }
                  },
                  {
                    title: vm.$t('button_close')
                  }
                ]
              })
            } else {
              vm.proceessDispostion()
            }
          } else if (response.message && response.message != '') {
            vm.$notify({
              type: 'warn',
              title: response.message
            })
          }
        },
        function (error) {
          error = error.error
          let message = false
          // if (error.message) {
          //   message = error.message
          // }
          vm.eventTrackingFailureLocalised()
          vm.eventTrackingLocalisationError({id: 'error-zip-code', error: 'invalid-zip-code'})
          if (error.code === vm.$const.LOCALIZATION_CODE.UNAVALIABLE) {
            message = vm.$t('message_find_outlet_empty')
            vm.$modal.show('dialog', {
              text: message,
              buttons: [
                {
                  title: vm.$t('button_close')
                }
              ]
            })
          } else if (error.code === vm.$const.LOCALIZATION_CODE.NOTOPEN) {
            message = vm.$t('localization.notopen')
            vm.$modal.show('dialog', {
              text: message,
              buttons: [
                {
                  title: vm.$t('button_close')
                }
              ]
            })
          } else {
            message = vm.$t('modal.advance_order_message')
            vm.$modal.show(ASAPPopup, {outletId: outletId, orderType: vm.$const.DELIVERY, outletDataZip: outletData, hideASAP: 'true', message: message})
          }
        }
      )
    },
    proceessDispostion () {
      var vm = this
      let data = {address_code: vm.disposition.addressCode}
      this.axios.apiOutlet.getAddressByAddressCode(data,
        function (response) {
          response = response.data.data.items
          if (response.length > 0) {
            vm.disposition.prefectureName = response[0].level1
            vm.disposition.cityName = response[0].level2
            vm.disposition.villeageName = response[0].level3
            vm.disposition.st = response[0].level4
          }
          vm.eventTrackingLocalisationSuccess(vm)
          vm.$disposition.set(vm, vm.disposition)
          vm.setDisposition(vm.disposition)
          vm.$session.remove('cart')
          vm.$root.$emit('cartUpdate')
          // vm.$root.$emit('onProcessChangeCategory')
          vm.redirectToPage(vm.$const.SLUG_LIST.DEAL)
        },
        function (error) {}
      )
    }
  }
}
</script>
<style scoped>
  ul,ol{
    list-style-type: none;
  }
  .p-10 {
    padding: 10px !important;
  }

</style>
